.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.add-button-container {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  padding-left: 0vw; /* Adjust this as needed for spacing */
  padding-bottom: 5px;
}

.add-button {
  position: relative;
  font-size: 20px;
  background-color: #94fe8e;
  border-radius: 10px;
  cursor: pointer;
}

table 			      { 
  border-spacing: 1; 
  border-collapse: collapse; 
  background:white;
  border-radius:10px;
  overflow:hidden;
  max-width: 90vw; 
  width:100%;
  position:relative;
  
  *               { position:relative }
  
  td,th           { padding-left:8px; font-size: calc(10px + 2vmin); text-align: left;}

  thead tr        { 
    height:60px;
    background:#3c3c3c;
    color: #fff;
    font-size:16px;
  }
  
  tbody tr        { height:48px; border-bottom:1px solid #E3F1D5 ;
    &:last-child  { border:0; }
  }
}

.select {   
  font-size: calc(10px + 2vmin);
  min-width: 10vw;
  text-align: center;
  border-color: #fff;
  border-radius: 12px; 
  cursor: pointer;
}
td {
  cursor: pointer;
}
tr:nth-child(even) {
  background-color: #e4e4e4;
}

option {
  background-color: #fff;
}

.pending {
  background-color: yellow;
}

.interview {
  background-color: orange;
}

.accepted {
  background-color: green;
}

.declined {
  background-color: red;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #282c34;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  padding-top: 0px;
  max-width: 500px;
  margin: auto;
  border-radius: 10px;
}

.aligned-form {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-group label {
  width: 100px;
  text-align: right;
  margin-right: 10px;
}

.form-group input,
.form-group select,
.form-group textarea {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 275px
}

.tags-input {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 275px;
}

.tag-item {
  display: flex;
  align-items: center;
}

.tag-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 50px !important;
}

.remove-tag-btn {
  padding: 3px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
}

.remove-tag-btn:hover {
  background-color: #ff1a1a;
}

.add-tag-btn {
  padding: 4px 8px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
}

.add-tag-btn:hover {
  background-color: #0056b3;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  font-size: 30px;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  padding-bottom: 5px;
}

.submit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.submit {
  background-color: #3498db; 
  color: #fff; 
  border: none; 
  border-radius: 15px;
  padding: 12px 20px; 
  font-size: 13px; 
  font-weight: bold; 
  cursor: pointer; 
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
}

.delete {
  background-color: #db3434; 
  color: #fff; 
  border: none; 
  border-radius: 15px;
  padding: 12px 20px; 
  font-size: 13px; 
  margin-left: 10px;
  font-weight: bold; 
  cursor: pointer; 
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
}

/* Hover effect */
.submit:hover {
  background-color: #2980b9; /* Darker blue on hover */
}

/* Active effect */
.submit:active {
  transform: scale(0.98); /* Slightly shrink on click */
}

/* Hover effect */
.delete:hover {
  background-color: #9d2323; /* Darker blue on hover */
}

/* Active effect */
.delete:active {
  transform: scale(0.98); /* Slightly shrink on click */
}

.form-title {
  display: inline-block;
  font-size: 19px;
  font-weight: bold;
  width: 100px; 
}